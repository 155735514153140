import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { TABLA } from '@/utils/consts'

export default {
  async selectLparteNovedad (Vue, idlparteNovedad) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idlparte_novedad', idlparteNovedad)
    const resp = await Vue.$api.call('lparteNovedad.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectParteNovedad (Vue, idparteNovedad) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idparte_novedad', idparteNovedad)
    const resp = await Vue.$api.call('parteNovedad.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectFicheroLparteNovedadCount (Vue, idlparteNovedad, esTecnico, esComercial, esVigilante) {
    const apiFilter = Vue.$online.fichero.buildApiFilterVisualizacion(idlparteNovedad, TABLA.lparte_novedad.idtabla, esTecnico, esComercial, esVigilante)
    const resp = await Vue.$api.call('fichero.select', { filter: apiFilter, wrapper: 'count' })

    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectFicheroLparteNovedad (Vue, idlparteNovedad, esTecnico, esComercial, esVigilante) {
    const apiFilter = Vue.$online.fichero.buildApiFilterVisualizacion(idlparteNovedad, TABLA.lparte_novedad.idtabla, esTecnico, esComercial, esVigilante)
    const resp = await Vue.$api.call('fichero.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectDatoLparteNovedad (Vue, idlparteNovedad) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
      .addExact('idlparte_novedad', idlparteNovedad)
    const resp = await Vue.$api.call('datoLparteNovedad.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async deleteLparteNovedad (Vue, idlparteNovedad) {
    await Vue.$api.call('lparteNovedad.delete', { idlparte_novedad: idlparteNovedad })
  }
}
