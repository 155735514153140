<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        @click-row="clickSummaryRow"
      >
        <template
          #extraSubtitle
        >
          <v-chip
            v-if="formattedItem.es_incidencia"
            dark
            small
            color="red"
            label
            class="ml-1"
          >
            Incidencia
          </v-chip>
        </template>
      </b10-view-summary>
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <b10-file-gallery
        v-if="hasViewPerm(permissions.lparteNovedadAdjunto.id)"
        class="pa-3"
        :images="ficherosGaleria"
      />
      <b10-fab-button
        v-if="parteNovedad && parteNovedad.estado === PARTE_NOVEDAD.estados.pendiente && hasInsertPerm(permissions.lparteNovedadAdjunto.id)"
        @click="clickAddAdjunto"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './LparteNovedadViewData'
import { get } from 'vuex-pathify'
import { LPARTE_NOVEDAD, PARTE_NOVEDAD, TABLA, TDATO_NOVEDAD } from '@/utils/consts'
import B10FileGallery from '../../../components/B10FileGallery'
import { toDate } from '@/utils/date'
import { getLatLongURL } from '@/utils/maps'

export default {
  components: { B10FileGallery },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      toolbarOptions: {
        edit: {
          title: 'Modificar',
          visible: true,
          icon: 'edit'
        },
        delete: {
          title: 'Eliminar',
          icon: 'delete',
          visible: true
        }
      },
      moreInfoRows: [
        { name: 'fnovedad', filter: this.$options.filters.shortDateTime },
        'tnovedad_descripcion',
        'descripcion',
        'estado_descripcion',
        { name: 'fuerza_seguridad_descripcion', label: 'Entidad notificada'},
        { name: 'informe_cliente', filter: this.$options.filters.humanizeBoolean },
        { name: 'geolocalizacion', label: 'Geolocalización', clickable: true },
        'punto_codigo',
        'punto_descripcion',
      ],
      showingDialogs: {
        moreInfo: false,
      },
      parteNovedad: {},
      ficherosGaleria: [],
      PARTE_NOVEDAD,
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idlparte_novedad
        item.fechaNovedad = this.$options.filters.shortDateTime(item.fnovedad)
        item.title = item.fechaNovedad
        item.subtitle = [item.tnovedad_descripcion, item.descripcion]
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        if (item.estado === LPARTE_NOVEDAD.estados.pendiente) {
          item.badge = 'Pendiente'
          item.badgeColor = LPARTE_NOVEDAD.colores.pendiente
        } else {
          item.badge = 'Finalizado'
          item.badgeColor = LPARTE_NOVEDAD.colores.finalizado
        }
        item.geolocalizacion = `${item.latitud}, ${item.longitud}`
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = `Novedad de parte Nº${this.routeParams.idlparte_novedad}`
      // parte de novedad
      const [datasetParteNovedad] = await Data.selectParteNovedad(this, this.routeParams.idparte_novedad)
      this.parteNovedad = datasetParteNovedad
      this.toolbarOptions.edit.visible = this.parteNovedad.estado === PARTE_NOVEDAD.estados.pendiente
      this.toolbarOptions.delete.visible = this.parteNovedad.estado === PARTE_NOVEDAD.estados.pendiente
      // adjuntos de todas las novedades
      const [datasetFicheros] = await Data.selectFicheroLparteNovedad(
        this,
        this.routeParams.idlparte_novedad,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial,
        !!this.usuarioIdvigilante
      )
      this.ficherosGaleria = datasetFicheros
      await this.loadDetailTotals()
      // datos dinámicos
      const [datasetDatoLparteNovedad] = await Data.selectDatoLparteNovedad(this, this.routeParams.idlparte_novedad)
      for (const dato of datasetDatoLparteNovedad) {
        const datoDinamico = {
          name: dato.iddato_lparte_novedad.toString(),
          label: dato.tdato_novedad_descripcion,
        }
        if (dato.tdato_novedad_tipo_valor === TDATO_NOVEDAD.tipos.fecha) {
          datoDinamico.value = this.$options.filters.shortDate(toDate(dato.valor))
        } else {
          datoDinamico.value = dato.valor
        }
        this.moreInfoRows.push(datoDinamico)
      }
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectLparteNovedad(this, this.routeParams.idlparte_novedad)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      if (this.hasViewPerm(this.permissions.lparteNovedadAdjunto.id)) {
        const detailFicheros = this.addDetail(
          'adjuntos', 'Adjuntos', 'Fotografías y ficheros adjuntos', 'attach'
        )
        const [datasetFicheros] = await Data.selectFicheroLparteNovedadCount(
          this,
          this.routeParams.idlparte_novedad,
          !!this.usuarioIdtecnico,
          !!this.usuarioIdcomercial,
          !!this.usuarioIdvigilante
        )
        detailFicheros.badge = datasetFicheros.count || 0
      }
    },
    clickDetail (data) {
      if (data.detail.name === 'adjuntos') {
        this.$appRouter.push({
          name: 'ficheros__partes-novedad-novedades-fichero-list',
          params: {
            id: this.routeParams.idlparte_novedad,
            idtabla: TABLA.lparte_novedad.idtabla,
            readonly: this.parteNovedad.estado === PARTE_NOVEDAD.estados.finalizado,
          },
        })
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.edit) {
        this.$appRouter.push({
          name: 'partesnovedad__lparte-novedad-edit',
          params: {
            idparte_novedad: this.routeParams.idparte_novedad,
            idlparte_novedad: this.routeParams.idlparte_novedad
          }
        })
      } else if (option === this.toolbarOptions.delete) {
        const res = await this.$alert.showConfirm(
          `¿Deseas eliminar la novedad del parte Nº${this.routeParams.idparte_novedad}?`
        )
        if (res) {
          await Data.deleteLparteNovedad(this, this.routeParams.idlparte_novedad)
          await this.$dirty.deleted(this.$dirty.ENTITIES.remote.lparteNovedad, this.routeParams.idlparte_novedad)
          this.$alert.showSnackbarSuccess('Novedad eliminada')
          this.$appRouter.go(-1)
        }
      }
    },
    async clickAddAdjunto () {
      this.$appRouter.push({
        name: 'ficheros__partes-novedad-novedades-fichero-add',
        params: {
          idtabla: TABLA.lparte_novedad.idtabla,
          id: this.routeParams.idlparte_novedad
        }
      })
    },
    clickSummaryRow (row) {
      if (row.name === 'geolocalizacion') {
        window.open(getLatLongURL(this.item.dataset.latitud, this.item.dataset.longitud), '_blank')
      }
    },
  }
}
</script>
